@import url('styles/antd.common.variable.css');

/*
    Common CSS - excluding themable components
*/
.mouseHand {
    cursor: pointer;
}

.app-title-small {
    padding: 10px;
    height: 64px;
    margin-left: 8px;
}

.app-title-big {
    max-width: 164px;
    padding: 16px 24px;
}

.app-logo-header {
    height: 46px;
}

.app-title {
    color: var(--app-title-color);
    font-size: 14px;
    font-weight: bold;
}

.usrprflnk,
a .usrprflnk:hover {
    color: var(--header-user-name-color);
}

.system .ant-card-body {
    padding: 5px;
}

.system .ant-page-header-heading {
    display: none;
}

.system .ant-page-header {
    padding: 5px;
}

.engagement .ant-card-body {
    padding: 0px;
}

/*
    Assessment Cards
*/

.tmlncardttl .ant-card-head {
    background-color: var(--tmlncardttl-ant-card-head-bg-color);
}

.tmlncardttl .ant-card-head .ant-btn-link {
    color: var(--tmlncardttl-card-head-link-color);
    font-weight: bold;
}

.tmlncardttl .ant-card-head .ant-btn-link:hover {
    color: #177ddc;
}

.otrtmln .inrcard {
    background-color: var(--body-background);
}

.otrtmln .inrcard .ant-card-extra .ant-typography {
    color: #fff;
    padding: 3px 10px;
    border-radius: 2px;
}

.otrtmln .inrcard .ant-card-extra .ant-typography-success {
    background-color: green;
}

.otrtmln .inrcard .ant-card-extra .ant-typography-danger {
    background-color: red;
}

.otrtmln .inrcard .ant-card-extra .ant-typography-warning {
    background-color: #d89614;
}

.inrcard .ant-card-actions .hide,
.hide {
    display: none;
}

/*
    State and Navigation buttons
*/
.nvgn-btn {
    margin: 0.6rem;
    min-width: 5.5rem;
}

.wrklstbtns {
    margin: 0;
    width: auto;
}

.nvgn-btn:hover {
    font-weight: bold;
}

.nvgn-btn:hover {
    color: #fff;
}

.stt-btn-sngl
{
    margin-right: 0.2rem;
    margin-left: 0.2rem;
}
.stt-btn-sngl:hover
{
    color: var(--ant-primary-color-hover) !important;
    border-color: var(--ant-primary-color-hover) !important;
}

.shwevl .ant-radio-disabled+span,
.shwevl .ant-checkbox-disabled+span {
    color: var(--disabled-color);
}

.shwevl .ant-radio-checked+span,
.shwevl .ant-checkbox-checked+span {
    color: orange;
}

.shwevl .crctopt .ant-radio+span,
.shwevl .crctopt .ant-checkbox+span {
    color: green;
}

.navbtn {
    background: var(--navbtn-bg-color);
    border: 1px solid var(--navbtn-border-color);
    text-align: right;
}


.stt-btnUpdated:hover {
    font-weight: bold;
}


/* Styling for Form Buttons with state buttons */
.frm-wt-st-btn .stt-frm-btn-st {
    float: left;
    text-align: left;
    margin-left: 1em;
}

.frm-wt-st-btn .stt-frm-btn-frm {
    float: right;
    text-align: right;
}

.role-checkbox {
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 10px;
}

/*
        Sign-in Buttons
*/
.signin-button {
    border-color: var(--signin-btn-bg-color);
    background: var(--signin-btn-bg-color);
}

.signin-button:hover,
.signin-button:focus {
    border-color: #7C51A8;
    background: #7C51A8;
}

/*
    Contract Table
*/
.contracttable tr.ant-table-expanded-row>td {
    background: var(--contracttable-bg-color);
}

/*
    Navigation
*/
nav.ant-breadcrumb span.ant-breadcrumb-link .anticon {
    margin-right: 4px;
}

/*
    Mermaid Modal
*/
.hstrymdl .ant-modal-content {
    border: 1px solid var(--tooltip-inner-color);
}

#hstrymrmadmdl .edgeLabel {
    background-color: transparent !important;
    color: var(--tooltip-inner-color);
}

/*
    Timeline View Modal
*/
.ant-modal .ant-timeline-item-head {
    background-color: var(--tmlncardttl-ant-card-head-bg-color);
}

.ant-modal .ant-timeline-item-tail {
    color: var(--tmlncardttl-card-head-link-color)
}

/* Error State */
#hstrymrmadmdl #state-error-0 rect {
    fill: red;
}

/* Info Corner Overrides */
.infosec .ant-card-body {
    padding: 0;
}

.dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: red;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
}

/* Header Overrides */
.ant-layout-header {
    box-shadow: 0 2px 8px var(--shadow-color);
}

.ant-layout-footer {
    box-shadow: 0 -2px 8px var(--shadow-color);
}

/* React MDE Overrides - Theme components*/
.react-mde {
    border-color: var(--border-color-base);
}

.react-mde .image-tip {
    background-color: var(--layout-trigger-bg-color);
    border-top-color: var(--border-color-base);
}

.mde-header {
    background: var(--menu-dark-horizontal-item);
    border-bottom-color: var(--border-color-base);
}

.mde-header .mde-tabs button.selected {
    border-color: var(--border-color-base);
}

.mde-header ul.mde-header-group li.mde-header-item button {
    color: var(--mde-btn-color);
}

.mde-textarea-wrapper textarea {
    background-color: var(--body-background);
    color: var(--heading-color);
}

.field-loading-spinner .ant-spin-dot {
    top: 70% !important;
    left: 5% !important;
}